jQuery.fn.exists = function() {
   return this.length > 0;
};

(function($) {

   firstload = true;

   $body = $('body');

   windowwidth = $(window).width();
   windowheight = $(window).height();

   var regex = new RegExp("(.*)\#(.*)", "gi");

   var ismobile = false;

   if (isMobile.phone || isMobile.tablet || isMobile.seven_inch || isMobile.any) {
      $body.addClass('qi-ismobile');
      ismobile = true;
   }

   $body.addClass('loaded');

   $(window).bind('scroll', function() {

   });

   $(window).on("resize", function() {

      if (windowwidth != $(window).width()) {

      }
      if (windowheight != $(window).height()) {

      }

   }).resize();

   $(document).ready(function() {

      if ($('.verticalcenter').exists()) {
         $('.verticalcenter').flexVerticalCenter();
      }
      if ($('.autoheight').exists()) {
         $('.autoheight').matchHeight({
            property: 'min-height'
         });
      }


      if (location.hash) {
         setTimeout(function() {
            window.scrollTo(0, 0);
         }, 1);
         setTimeout(function() {
            id = window.location.hash;

            $.scrollTo(id, 500, {
               offset: -35
            });
         }, 600);
      }

      // Select all links with hashes
      $('.site-header ul li a[href*="#"]')
         // Remove links that don't actually link to anything
         .not('[href="#"]')
         .not('[href="#0"]')
         // On-page links
         .click(function(event) {
            if (
               location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
               location.hostname == this.hostname
            ) {
               // Figure out element to scroll to
               var target = $(this.hash);
               var the_hash = $(this).attr("href");

               target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
               // Does a scroll target exist?
               if (target.length) {

                  // Only prevent default if animation is actually gonna happen
                  event.preventDefault();

                  $.scrollTo(target, 500, {
                     offset: -35
                  });
               }
            }
         });

      $("#nav-trigger span").click(function() {
         if ($("nav#nav-mobile ul").hasClass("expanded")) {
            $("nav#nav-mobile ul.expanded").removeClass("expanded").slideUp(250);
            $(this).removeClass("open");
         } else {
            $("nav#nav-mobile ul").addClass("expanded").slideDown(250);
            $(this).addClass("open");
         }
      });

      $("#nav-mobile ul li a").click(function() {
         if ($("nav#nav-mobile ul").hasClass("expanded")) {
            $("nav#nav-mobile ul.expanded").removeClass("expanded").slideUp(250);
            $("#nav-trigger span").removeClass("open");
         } else {
            $("nav#nav-mobile ul").addClass("expanded").slideDown(250);
            $("#nav-trigger span").addClass("open");
         }
      });

   });

   firstload = false;

})(jQuery);